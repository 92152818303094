import Ua from '../libs/j_ua';
import { AddClass, RemoveClass, GetParents } from '../libs/j_utility';

const UA = new Ua();
import jAccordion from '../libs/j_accordion_es';

export default () => {


	// ----------------------------------------------------------
	// Accordion
	// ----------------------------------------------------------

	const J_ACCORDION_SEARCH = new jAccordion(
		".j-accordion",
		{
			toggleSpeed: 300
		}
	);

	// ----------------------------------------------------------
	// Search Pulldown
	// ----------------------------------------------------------

	const TARGET = document.querySelector('.j-search_pulldown');
	const TARGET_BTN = document.querySelector('.j-search_pulldown_btn');
	const TARGET_LIST = document.querySelector('.j-search_pulldown_list');
	// const TARGET_LIST_SELECT = TARGET_LIST.querySelector('.is-select');
	const TARGET_LIST_LI = TARGET_LIST.querySelectorAll('li');
	const TARGET_SELECT = document.querySelector('.f-form_select select');

	// PCのカテゴリを変更した場合は、SPに反映
	const CHANGE_CAT_PC = ( index ) => {
		TARGET_SELECT.options[index].selected = true;
	}

	// SPのカテゴリを変更した場合は、PCドロップダウンに反映
	const CHANGE_CAT_SP = () => {

		for (var i = 0; i < TARGET_SELECT.options.length; i++) {
			if( TARGET_SELECT.options[i].selected ){
				SET_BTN_VALUE( TARGET_LIST_LI[i].textContent );
				TOGGLE_CLASS( TARGET_LIST_LI[i] );
			}
		}

	}

	const SET_BTN_VALUE = ( value ) => {

		TARGET_BTN.innerText = '';
		TARGET_BTN.innerText = value;

	}

	const TOGGLE_CLASS = ( target ) => {
		for (var i = 0; i < TARGET_LIST_LI.length; i++) {
			RemoveClass( TARGET_LIST_LI[i], 'is-select' );
		}

		AddClass( target, 'is-select' );
	}



	// selectとドロップダウンを合わせる
	CHANGE_CAT_SP();

	//PC用ドロップダウンを変更した場合
	for (var i = 0; i < TARGET_LIST_LI.length; i++) {
		TARGET_LIST_LI[i].addEventListener( 'click', (e) => {

			// アコーディオン閉じる
			J_ACCORDION_SEARCH.Close( ".j-accordion_btn" );

			// ボタンのテキストを変更
			SET_BTN_VALUE( e.currentTarget.textContent );

			// J_ACCORDION_SEARCH.CloseEnd = function(){ // ちらつき予防

				// 選択済みclass変更
				let index = Array.prototype.indexOf.call( TARGET_LIST_LI, e.currentTarget);
				TOGGLE_CLASS( e.currentTarget );

				// select optionの状態も変更
				CHANGE_CAT_PC(index);

			// };

		} )
	}

	//SP用 selectタグ を変更した場合
	TARGET_SELECT.addEventListener( 'change', (e) => {

		CHANGE_CAT_SP();

	} )

}
