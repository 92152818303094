(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _j_utility = require('../libs/j_utility');

var _j_ua = require('../libs/j_ua');

var _j_ua2 = _interopRequireDefault(_j_ua);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UA = new _j_ua2.default();

exports.default = function () {

	// ----------------------------------------------------------
	// Hover class
	// ----------------------------------------------------------

	var TARGET = document.getElementsByTagName('a');
	var CLASS_NAME = 'is-hover';

	var HoverStart = void 0,
	    HoverEnd = void 0;

	if (!UA.isSp && !UA.isTab) {
		HoverStart = 'mouseenter', HoverEnd = 'mouseleave';
	} else {
		HoverStart = 'touchstart', HoverEnd = 'touchend';
	}

	var HOVER_START_FNC = function HOVER_START_FNC(e) {
		(0, _j_utility.AddClass)(e.currentTarget, CLASS_NAME);
	};

	var HOVER_END_FNC = function HOVER_END_FNC(e) {
		(0, _j_utility.RemoveClass)(e.currentTarget, CLASS_NAME);
	};

	for (var i = 0; i < TARGET.length; i++) {

		TARGET[i].removeEventListener('transitionend', HOVER_START_FNC);

		TARGET[i].addEventListener(HoverStart, HOVER_START_FNC);

		TARGET[i].addEventListener(HoverEnd, HOVER_END_FNC);
	}
};

},{"../libs/j_ua":9,"../libs/j_utility":10}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _j_ua = require('../libs/j_ua');

var _j_ua2 = _interopRequireDefault(_j_ua);

var _j_utility = require('../libs/j_utility');

var _j_accordion_es = require('../libs/j_accordion_es');

var _j_accordion_es2 = _interopRequireDefault(_j_accordion_es);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UA = new _j_ua2.default();

exports.default = function () {

	// ----------------------------------------------------------
	// Accordion
	// ----------------------------------------------------------

	var J_ACCORDION_SEARCH = new _j_accordion_es2.default(".j-accordion", {
		toggleSpeed: 300
	});

	// ----------------------------------------------------------
	// Search Pulldown
	// ----------------------------------------------------------

	var TARGET = document.querySelector('.j-search_pulldown');
	var TARGET_BTN = document.querySelector('.j-search_pulldown_btn');
	var TARGET_LIST = document.querySelector('.j-search_pulldown_list');
	// const TARGET_LIST_SELECT = TARGET_LIST.querySelector('.is-select');
	var TARGET_LIST_LI = TARGET_LIST.querySelectorAll('li');
	var TARGET_SELECT = document.querySelector('.f-form_select select');

	// PCのカテゴリを変更した場合は、SPに反映
	var CHANGE_CAT_PC = function CHANGE_CAT_PC(index) {
		TARGET_SELECT.options[index].selected = true;
	};

	// SPのカテゴリを変更した場合は、PCドロップダウンに反映
	var CHANGE_CAT_SP = function CHANGE_CAT_SP() {

		for (var i = 0; i < TARGET_SELECT.options.length; i++) {
			if (TARGET_SELECT.options[i].selected) {
				SET_BTN_VALUE(TARGET_LIST_LI[i].textContent);
				TOGGLE_CLASS(TARGET_LIST_LI[i]);
			}
		}
	};

	var SET_BTN_VALUE = function SET_BTN_VALUE(value) {

		TARGET_BTN.innerText = '';
		TARGET_BTN.innerText = value;
	};

	var TOGGLE_CLASS = function TOGGLE_CLASS(target) {
		for (var i = 0; i < TARGET_LIST_LI.length; i++) {
			(0, _j_utility.RemoveClass)(TARGET_LIST_LI[i], 'is-select');
		}

		(0, _j_utility.AddClass)(target, 'is-select');
	};

	// selectとドロップダウンを合わせる
	CHANGE_CAT_SP();

	//PC用ドロップダウンを変更した場合
	for (var i = 0; i < TARGET_LIST_LI.length; i++) {
		TARGET_LIST_LI[i].addEventListener('click', function (e) {

			// アコーディオン閉じる
			J_ACCORDION_SEARCH.Close(".j-accordion_btn");

			// ボタンのテキストを変更
			SET_BTN_VALUE(e.currentTarget.textContent);

			// J_ACCORDION_SEARCH.CloseEnd = function(){ // ちらつき予防

			// 選択済みclass変更
			var index = Array.prototype.indexOf.call(TARGET_LIST_LI, e.currentTarget);
			TOGGLE_CLASS(e.currentTarget);

			// select optionの状態も変更
			CHANGE_CAT_PC(index);

			// };
		});
	}

	//SP用 selectタグ を変更した場合
	TARGET_SELECT.addEventListener('change', function (e) {

		CHANGE_CAT_SP();
	});
};

},{"../libs/j_accordion_es":6,"../libs/j_ua":9,"../libs/j_utility":10}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _j_ua = require('../libs/j_ua');

var _j_ua2 = _interopRequireDefault(_j_ua);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UA = new _j_ua2.default();

exports.default = function () {

	// ----------------------------------------------------------
	// SNS Share
	// ----------------------------------------------------------

	var TARGET = document.querySelectorAll('.j-share [data-sns]');

	for (var i = 0; i < TARGET.length; i++) {

		TARGET[i].addEventListener('click', function (e) {

			var dataType = e.currentTarget.getAttribute("data-sns"),
			    shareURL = void 0,
			    shareType = void 0,
			    shareOpt = void 0;

			//表示中のURLをシェア
			var siteURL = encodeURIComponent(location.href);

			//指定URLをシェア
			// let dataURL = TARGET[i].parents('.j-share').attr('data-url'),
			// if( dataURL ){
			// 	if( dataURL.indexOf('http') > -1 ){
			// 		siteURL = encodeURIComponent( dataURL );
			// 	}else{
			// 		siteURL = encodeURIComponent( location.origin + dataURL);
			// 	}
			// }

			if (dataType == "fb") {
				//FB

				shareURL = 'https://www.facebook.com/sharer/sharer.php?u=' + siteURL;
				shareOpt = 'scrollbars=yes,location=yes,toolbar=no,width=670,height=340';
			} else if (dataType == "tw") {
				//TW

				shareURL = 'https://twitter.com/intent/tweet?url=' + siteURL + '&text=' + encodeURIComponent(document.title);
				shareOpt = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=550,height=420';
			} else if (dataType == "line") {
				//Line

				if (UA.isSp || UA.isTab) {
					// アプリ版
					shareURL = 'http://line.me/R/msg/text/?' + siteURL;
				} else {
					// Web版
					shareURL = 'https://timeline.line.me/social-plugin/share?url=' + siteURL;
				}
				shareOpt = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=500,height=550';
			}

			window.open(shareURL, null, shareOpt);
		});
	}
};

},{"../libs/j_ua":9}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _j_utility = require('../libs/j_utility');

exports.default = function () {

	// ----------------------------------------------------------
	// Show Toggle Pagetop
	// ----------------------------------------------------------

	var TARGET = document.querySelector('html');

	var interval = 10,
	    className = 'is-scroll',
	    showFlag = false,
	    fixFlag = false;

	var GetScrollTopFunc = function GetScrollTopFunc() {
		return window.pageYOffset;
	};

	var ShowToggleFunc = function ShowToggleFunc() {

		if (GetScrollTopFunc() > interval) {

			if (!showFlag) {
				(0, _j_utility.AddClass)(TARGET, className);
			}

			showFlag = true;
		} else {

			if (showFlag) {
				(0, _j_utility.RemoveClass)(TARGET, className);
			}

			showFlag = false;
		}
	};

	if (TARGET) {

		document.addEventListener('scroll', function () {

			ShowToggleFunc();
		});

		document.addEventListener('load', function () {

			ShowToggleFunc();
		});
	}
};

},{"../libs/j_utility":10}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _j_utility = require('../libs/j_utility');

var _j_ua = require('../libs/j_ua');

var _j_ua2 = _interopRequireDefault(_j_ua);

var _share = require('./_share');

var _share2 = _interopRequireDefault(_share);

var _search_pulldown = require('./_search_pulldown');

var _search_pulldown2 = _interopRequireDefault(_search_pulldown);

var _show_pagetop = require('./_show_pagetop');

var _show_pagetop2 = _interopRequireDefault(_show_pagetop);

var _hover_touch = require('./_hover_touch');

var _hover_touch2 = _interopRequireDefault(_hover_touch);

var _j_menu_es = require('../libs/j_menu_es');

var _j_menu_es2 = _interopRequireDefault(_j_menu_es);

var _j_smoothScroll_es = require('../libs/j_smoothScroll_es');

var _j_smoothScroll_es2 = _interopRequireDefault(_j_smoothScroll_es);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Init = function Init() {

    // ----------------------------------------------------------
    // UA
    // ----------------------------------------------------------

    var UA = new _j_ua2.default();

    if (UA.isIE) {
        (0, _j_utility.AddClass)(document.getElementsByTagName('html')[0], 'is-ie');
    }
    if (UA.isFirefox) {
        (0, _j_utility.AddClass)(document.getElementsByTagName('html')[0], 'is-ff');
    }

    // ----------------------------------------------------------
    // HoverTouch
    // ----------------------------------------------------------

    (0, _hover_touch2.default)();

    // ----------------------------------------------------------
    // ShowPagetop
    // ----------------------------------------------------------

    (0, _show_pagetop2.default)();

    // ----------------------------------------------------------
    // Search Pulldown
    // ----------------------------------------------------------

    (0, _search_pulldown2.default)();

    // ----------------------------------------------------------
    // Share
    // ----------------------------------------------------------

    (0, _share2.default)();

    // ----------------------------------------------------------
    // Menu
    // ----------------------------------------------------------

    var J_MENU = new _j_menu_es2.default(".j-menu", {
        globalNav: ".j-menu_gnav",
        activeName: "is-open"
    });

    var J_MENU_SEARCH = new _j_menu_es2.default(".j-menu_search", {
        globalNav: ".j-menu_search_wrap",
        activeName: "is-open"
    });

    var SEARCH_CLOSE_BTN = document.querySelector('.header-search .header-menubtn');
    SEARCH_CLOSE_BTN.addEventListener('click', function () {
        J_MENU_SEARCH.Close();
    });

    // ----------------------------------------------------------
    // SmoothScroll
    // ----------------------------------------------------------

    var J_SCROLL = new _j_smoothScroll_es2.default("a", {
        speed: 1000,
        easing: "easeOutQuint",
        ignore: ".ignore"
    });
};

exports.default = Init;

},{"../libs/j_menu_es":7,"../libs/j_smoothScroll_es":8,"../libs/j_ua":9,"../libs/j_utility":10,"./_hover_touch":1,"./_search_pulldown":2,"./_share":3,"./_show_pagetop":4}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/*

MeganeTemplate

Version: 5.0.0
Website: http://megane-template.com/
License: Dentsu Isobar All Rights Reserved.

*/
var jAccordion = function () {
    function jAccordion(selector, option) {
        _classCallCheck(this, jAccordion);

        this.selector = selector;
        this.accordion = document.querySelectorAll(this.selector);

        //option
        if (option == null) option = {};
        this.toggleSpeed = option.toggleSpeed ? option.toggleSpeed : 1;
        this.btnElm = option.btnElm ? option.btnElm : ".j-accordion_btn";
        this.detailElm = option.detailElm ? option.detailElm : ".j-accordion_detail";

        this.OpenEnd = function () {};
        this.CloseEnd = function () {};

        this.clickFlag = false;

        if (this.accordion[0]) {
            this.Init();
        }
    }

    _createClass(jAccordion, [{
        key: "AddTransition",
        value: function AddTransition(target) {
            target.style.transition = "max-height " + this.toggleSpeed / 1000 + "s";
            target.style.setProperty('-webkit-transition', "max-height " + this.toggleSpeed / 1000 + "s");
        }
    }, {
        key: "RemoveTransition",
        value: function RemoveTransition(target) {
            target.style.transition = "none";
            target.style.setProperty('-webkit-transition', "none");
        }

        /**
        **
        ** Init
        **
        **/

    }, {
        key: "Init",
        value: function Init() {
            var _this = this;

            var initCss = "overflow: hidden;";
            initCss += "transition: max-height " + this.toggleSpeed / 1000 + "s ;";
            initCss += "-webkit-transition: max-height " + this.toggleSpeed / 1000 + "s ;";

            for (var i = 0; i < this.accordion.length; i++) {

                var DETAIL = this.accordion[i].querySelectorAll(this.detailElm);
                var BTN = this.accordion[i].querySelectorAll(this.btnElm);

                for (var j = 0; j < BTN.length; j++) {

                    if (DETAIL[j]) {
                        DETAIL[j].style.cssText = initCss;
                        if (!this.HasClass(DETAIL[j], "active")) {
                            DETAIL[j].style.maxHeight = "0px";
                        }
                    }

                    BTN[j].addEventListener("click", function (e) {

                        e.preventDefault();

                        _this.Toggle(e);
                    });
                }
            }
        }

        /**
        **
        ** Toggle
        **
        **/

    }, {
        key: "Toggle",
        value: function Toggle(element) {

            if (!this.accordion[0]) {
                console.error(this.selector + ": Not Found");
                return false;
            }

            var TARGET = element.currentTarget ? element.currentTarget : document.querySelectorAll(element)[0];

            if (this.HasClass(TARGET, "active")) {

                if (!this.clickFlag) this.Close(element);
            } else {

                if (!this.clickFlag) this.Open(element);
            }
        }

        /**
        **
        ** Open, Close
        **
        **/

    }, {
        key: "Open",
        value: function Open(element) {
            var _this2 = this;

            if (!this.accordion[0]) {
                console.error(this.selector + ": Not Found");
                return false;
            }

            this.clickFlag = true;

            var TARGET = element.currentTarget ? element.currentTarget : document.querySelectorAll(element)[0];
            var PARENT = this.GetParent(TARGET, this.selector);

            var INDEX = Array.prototype.indexOf.call(PARENT.querySelectorAll(this.btnElm), TARGET);

            var TARGET_DETAIL = PARENT.querySelectorAll(this.detailElm)[INDEX];

            // TARGET_DETAIL.style.display = "block";
            TARGET_DETAIL.style.maxHeight = TARGET_DETAIL.scrollHeight + "px";

            var EndFunc = function EndFunc() {
                _this2.clickFlag = false;
                _this2.OpenEnd(INDEX);
                _this2.RemoveTransition(TARGET_DETAIL);
                TARGET_DETAIL.style.maxHeight = null;
                TARGET_DETAIL.removeEventListener("transitionend", EndFunc);
            };

            this.AddClass(TARGET, "active");
            this.AddClass(TARGET_DETAIL, "active");

            if (!this.accordion[0].classList) {
                this.clickFlag = false;
            } else {
                TARGET_DETAIL.addEventListener("transitionend", EndFunc, false);
            }
        }
    }, {
        key: "Close",
        value: function Close(element) {
            var _this3 = this;

            if (!this.accordion[0]) {
                console.error(this.selector + ": Not Found");
                return false;
            }

            this.clickFlag = true;

            var TARGET = element.currentTarget ? element.currentTarget : document.querySelectorAll(element)[0];
            var PARENT = this.GetParent(TARGET, this.selector);

            var INDEX = Array.prototype.indexOf.call(PARENT.querySelectorAll(this.btnElm), TARGET);

            var TARGET_DETAIL = PARENT.querySelectorAll(this.detailElm)[INDEX];
            var EndFunc = function EndFunc() {
                _this3.clickFlag = false;
                _this3.CloseEnd(INDEX);
                TARGET.style.pointerEvents = "inherit";
                // TARGET_DETAIL.style.display = "none";
                TARGET_DETAIL.removeEventListener("transitionend", EndFunc);
            };

            //

            this.RemoveClass(TARGET, "active");
            this.RemoveClass(TARGET_DETAIL, "active");

            TARGET_DETAIL.style.maxHeight = TARGET_DETAIL.scrollHeight + "px";
            setTimeout(function () {
                _this3.AddTransition(TARGET_DETAIL);
                TARGET_DETAIL.style.maxHeight = "0px";
            }, 10);

            if (!this.accordion[0].classList) {
                //IE9

                // TARGET_DETAIL.style.display = "none";
                this.clickFlag = false;
            } else {

                TARGET_DETAIL.addEventListener("transitionend", EndFunc, false);
            }
        }
    }, {
        key: "CloseIgnoreThis",
        value: function CloseIgnoreThis(element) {

            if (!this.accordion[0]) {
                console.error(this.selector + ": Not Found");
                return false;
            }

            var TARGET = element;
            var PARENT = this.GetParent(TARGET, this.selector);

            var INDEX = Array.prototype.indexOf.call(PARENT.querySelectorAll(this.btnElm), TARGET);

            var TARGET_BTN = PARENT.querySelectorAll(this.btnElm);
            var TARGET_DETAIL = PARENT.querySelectorAll(this.detailElm);

            for (var i = 0; i < TARGET_BTN.length; i++) {

                if (i != INDEX) {
                    TARGET_BTN[i].classList.remove("active");
                    Close_(i);
                }
            }

            //

            function Close_(i) {

                TARGET_DETAIL[i].classList.remove("active");

                setTimeout(function () {
                    TARGET_DETAIL[i].style.maxHeight = "0px";
                }, 100);
            }
        }

        /**
        **
        ** GetParent
        **
        **/

    }, {
        key: "GetParent",
        value: function GetParent(element, target) {

            var parent = element,
                i = 0,
                t = void 0;

            if (target.split(".")[1]) {

                t = target.split(".")[1];
            } else if (target.split("#")[1]) {

                t = target.split("#")[1];
            } else {

                t = target;
            }

            while (i < 100) {

                parent = parent.parentNode;

                if (parent.tagName.toLowerCase() == t) break;

                if (parent.className) {
                    if (parent.className.match(t)) break;
                } else if (parent.id) {
                    if (parent.id.match(t)) break;
                }

                i++;
            }

            return parent;
        }
    }, {
        key: "AddClass",
        value: function AddClass(element, _className) {

            if (element.classList) {
                element.classList.add(_className);
            } else {
                element.className += ' ' + _className;
            }
        }
    }, {
        key: "RemoveClass",
        value: function RemoveClass(element, _className) {

            if (element.classList) {
                element.classList.remove(_className);
            } else {
                element.className = element.className.replace(new RegExp('(^|\\b)' + _className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }
    }, {
        key: "HasClass",
        value: function HasClass(element, _className) {

            var ignore = false;

            if (element.classList) {
                if (element.classList.contains(_className)) ignore = true;
            } else {
                if (new RegExp('(^| )' + _className + '( |$)', 'gi').test(element.className)) ignore = true;
            }

            return ignore;
        }
    }]);

    return jAccordion;
}();

exports.default = jAccordion;

},{}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/*

MeganeTemplate

Version: 4.1.0
Website: http://megane-template.com/
License: Dentsu Isobar All Rights Reserved.

*/
var jMenu = function () {
    function jMenu(selector, option) {
        _classCallCheck(this, jMenu);

        this.selector = selector;
        this.menu = document.querySelectorAll(this.selector)[0];

        //option
        if (option == null) option = {};
        this.globalNav = option.globalNav ? option.globalNav : "#globalnav";
        this.activeName = option.activeName ? option.activeName : "active";
        this.closePoint = option.closePoint ? option.closePoint : null;

        this.noScroll = option.noScroll != null ? option.noScroll : true;
        this.globalNavElm = document.querySelectorAll(this.globalNav)[0];

        this.flag = false;
        this.scrollVal = null;
        this.baseWinWidth = window.innerWidth;

        this.OpenEnd = function () {};
        this.CloseEnd = function () {};

        if (this.menu) {
            this.Init();
        }
    }

    /**
    **
    ** Init
    **
    **/


    _createClass(jMenu, [{
        key: "Init",
        value: function Init() {

            var THAT = this;

            this.menu.addEventListener("click", function () {
                !THAT.flag ? THAT.Open() : THAT.Close();
            });

            window.addEventListener("resize", function () {

                var WIN_WIDTH = window.innerWidth;

                if (THAT.closePoint && THAT.flag) {

                    if (THAT.baseWinWidth < WIN_WIDTH) {
                        //右

                        if (THAT.baseWinWidth < THAT.closePoint && THAT.closePoint < WIN_WIDTH) {
                            THAT.Close();
                        }
                    } else {
                        //左

                        if (THAT.baseWinWidth > THAT.closePoint && THAT.closePoint > WIN_WIDTH) {
                            THAT.Close();
                        }
                    }
                    THAT.baseWinWidth = WIN_WIDTH;
                }
            });
        }

        /**
        **
        ** Open
        **
        **/

    }, {
        key: "Open",
        value: function Open(i) {

            if (!this.menu) {
                console.error(this.selector + ": Not Found");
                return false;
            }

            var THAT = this;
            this.scrollVal = document.documentElement && document.documentElement.scrollTop || document.body.scrollTop;

            this.AddClass(this.menu, this.activeName);
            this.AddClass(this.globalNavElm, this.activeName);

            var EndFunc = function EndFunc() {
                THAT.OpenEnd();
                THAT.globalNavElm.removeEventListener("transitionend", EndFunc);
            };

            this.globalNavElm.addEventListener("transitionend", EndFunc, false);

            if (this.noScroll) {

                this.htmlTag = document.getElementsByTagName("html")[0];

                var css = "position: fixed; ";
                css += "width: 100%; ";
                css += "top:" + -this.scrollVal + "px;";

                this.htmlTag.style.cssText = css;
            }

            this.flag = true;
        }

        /**
        **
        ** Close
        **
        **/

    }, {
        key: "Close",
        value: function Close() {

            if (!this.menu) {
                console.error(this.selector + ": Not Found");
                return false;
            }

            var THAT = this;

            if (this.noScroll) this.htmlTag.style.position = "static";

            window.scroll(0, this.scrollVal);

            this.RemoveClass(this.menu, this.activeName);
            this.RemoveClass(this.globalNavElm, this.activeName);

            var EndFunc = function EndFunc() {
                THAT.CloseEnd();
                THAT.globalNavElm.removeEventListener("transitionend", EndFunc);
            };

            this.globalNavElm.addEventListener("transitionend", EndFunc, false);

            this.flag = false;
        }
    }, {
        key: "AddClass",
        value: function AddClass(element, _className) {

            if (element.classList) {
                element.classList.add(_className);
            } else {
                element.className += ' ' + _className;
            }
        }
    }, {
        key: "RemoveClass",
        value: function RemoveClass(element, _className) {

            if (element.classList) {
                element.classList.remove(_className);
            } else {
                element.className = element.className.replace(new RegExp('(^|\\b)' + _className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }
    }]);

    return jMenu;
}();

exports.default = jMenu;

},{}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/*

MeganeTemplate

Version: 4.1.0
Website: http://megane-template.com/
License: Dentsu Isobar All Rights Reserved.

*/
var jSmoothScroll = function () {
    function jSmoothScroll(selector, option) {
        var _this = this;

        _classCallCheck(this, jSmoothScroll);

        this.selector = selector;
        this.btn = document.querySelectorAll(this.selector);

        //option
        if (option == null) option = {};
        this.speed = option.speed ? option.speed : 800;
        this.easing = option.easing ? option.easing : "linear";
        this.posFix = option.posFix ? option.posFix : 0;
        this.ignore = option.ignore ? option.ignore : "";
        this.blank = option.blank ? option.blank : false;
        this.customAnchor = option.customAnchor ? option.customAnchor : "#/";

        this.moveFlag = false;

        this.ScrollEnd = function () {};

        if (this.btn[0]) {
            this.oldBrowser = this.GetAndroidVersion() < 4.4 || !this.btn[0].classList;
            this.Init();
        }

        if (this.blank) {
            window.addEventListener("load", function () {
                setTimeout(function () {
                    _this.AnchorLink();
                }, 200);
            });
        }
    }

    /**
    **
    ** Init
    **
    **/


    _createClass(jSmoothScroll, [{
        key: "Init",
        value: function Init() {
            var _this2 = this;

            for (var i = 0; i < this.btn.length; i++) {

                this.btn[i].addEventListener("click", function (e) {

                    var ignore = false;
                    var IGUNORE_TXT = _this2.ignore.split(",");

                    for (var j = 0; j < IGUNORE_TXT.length; j++) {
                        //not処理

                        var IGUNORE = IGUNORE_TXT[j].split(".")[1] ? IGUNORE_TXT[j].split(".")[1] : IGUNORE_TXT[j].split("#")[1];

                        if (e.currentTarget.classList) {
                            if (e.currentTarget.classList.contains(IGUNORE)) ignore = true;
                        } else {
                            if (new RegExp('(^| )' + IGUNORE + '( |$)', 'gi').test(e.currentTarget.className)) ignore = true;
                        }
                        if (e.currentTarget.id == IGUNORE) ignore = true;
                    }

                    if (!_this2.moveFlag) {
                        //ダブルクリック防止

                        var GET_HASH = e.currentTarget.getAttribute("href");

                        if (GET_HASH && GET_HASH.split("#")[0] == "" && !ignore) {

                            e.preventDefault();
                            _this2.moveFlag = true;

                            var HASH = GET_HASH.split("#")[1];
                            var TARGET = document.getElementById(HASH);
                            var OFFSET_TOP = _this2.GetOffset(TARGET).top;

                            _this2.SmoothScroll(OFFSET_TOP);
                        }
                    }
                });
            }
        }

        /**
        **
        ** SmoothScroll
        **
        **/

    }, {
        key: "SmoothScroll",
        value: function SmoothScroll(p) {
            var _this3 = this;

            var POSITION = p - this.posFix;

            var SCROLL_VAL = document.documentElement && document.documentElement.scrollTop || document.body.scrollTop;

            var DIFF = POSITION - SCROLL_VAL;
            var num = SCROLL_VAL;

            var START_TIME = new Date().getTime(); //描画開始時刻を取得
            var render = void 0;
            var numPrev = SCROLL_VAL;

            var Loop = void 0;

            var LoopAnim = function LoopAnim() {

                if (!_this3.oldBrowser) render = requestAnimationFrame(Loop);

                var CURRENT_TIME = new Date().getTime(); //経過時刻を取得
                var STATUS = CURRENT_TIME - START_TIME; // 描画開始時刻から経過時刻を引く

                var MOVE_Y = Math.round(Easing[_this3.easing](STATUS, SCROLL_VAL, Math.abs(DIFF), _this3.speed));

                if (DIFF > 0) {
                    num += MOVE_Y - numPrev;
                } else {
                    num += -(MOVE_Y - numPrev);
                }

                window.scroll(0, num);

                numPrev = MOVE_Y;

                if (STATUS >= _this3.speed) {

                    _this3.moveFlag = false;
                    _this3.ScrollEnd();

                    if (_this3.oldBrowser) {
                        clearInterval(Loop);
                    } else {
                        cancelAnimationFrame(render);
                    }

                    window.scroll(0, POSITION);
                }
            };

            if (this.oldBrowser) {
                Loop = setInterval(LoopAnim, 33); // Android4.4未満は setInterval で処理
            } else {
                Loop = LoopAnim;
                Loop();
            }
        }

        /**
        **
        ** GetOffset
        **
        **/

    }, {
        key: "GetOffset",
        value: function GetOffset(el) {

            var BOX = el.getBoundingClientRect();

            return {
                top: BOX.top + window.pageYOffset - document.documentElement.clientTop,
                left: BOX.left + window.pageXOffset - document.documentElement.clientLeft
            };
        }

        /**
        **
        ** AnchorLink
        **
        **/

    }, {
        key: "AnchorLink",
        value: function AnchorLink() {

            for (var i = 0; i < this.btn.length; i++) {

                var ignore = false;
                var IGUNORE_TXT = this.ignore.split(",");
                var GET_HASH = this.btn[i].getAttribute("href");

                for (var j = 0; j < IGUNORE_TXT.length; j++) {
                    //not処理

                    var IGUNORE = IGUNORE_TXT[j].split(".")[1] ? IGUNORE_TXT[j].split(".")[1] : IGUNORE_TXT[j].split("#")[1];

                    if (this.btn[i].classList) {
                        if (this.btn[i].classList.contains(IGUNORE)) ignore = true;
                    } else {
                        if (new RegExp('(^| )' + IGUNORE + '( |$)', 'gi').test(this.btn[i].className)) ignore = true;
                    }
                    if (this.btn[i].id.match(IGUNORE)) ignore = true;
                }

                if (GET_HASH && !ignore) {

                    var HREF_SPLIT = GET_HASH.split("#");

                    if (HREF_SPLIT.length > 1 && HREF_SPLIT[0] != "") {

                        this.btn[i].setAttribute("href", GET_HASH.replace(/#/g, this.customAnchor));
                    }
                }
            }

            if (location.href.split(this.customAnchor)[1]) {

                var TARGET = document.getElementById(location.href.split(this.customAnchor)[1]);
                var OFFSET_TOP = this.GetOffset(TARGET).top;
                this.SmoothScroll(OFFSET_TOP);
            }
        }
    }, {
        key: "GetAndroidVersion",
        value: function GetAndroidVersion() {

            var ua = navigator.userAgent;

            if (ua.toLowerCase().indexOf('android') > 0 && ua.toLowerCase().indexOf('mobile') > 0) {
                return parseFloat(ua.slice(ua.indexOf("Android") + 8));
            }
        }
    }]);

    return jSmoothScroll;
}();

/**
**
** Easing
**
** t : 時間(進行度)
** b : 開始の値(開始時の座標やスケールなど)
** c : 開始と終了の値の差分
** d : Tween(トゥイーン)の合計時間
**
**/


exports.default = jSmoothScroll;
var Easing = {

    "linear": function linear(t, b, c, d) {
        t /= d;
        return c * t + b;
    },

    //Cubic
    "easeInCubic": function easeInCubic(t, b, c, d) {
        t /= d;
        return c * t * t * t + b;
    },
    "easeOutCubic": function easeOutCubic(t, b, c, d) {
        t /= d;
        t = t - 1;
        return c * (t * t * t + 1) + b;
    },
    "easeInOutCubic": function easeInOutCubic(t, b, c, d) {
        t /= d / 2.0;
        if (t < 1) {
            return c / 2.0 * t * t * t + b;
        } else {
            t = t - 2;
            return c / 2.0 * (t * t * t + 2) + b;
        }
    },

    //Quartic
    "easeInQuart": function easeInQuart(t, b, c, d) {
        t /= d;
        return c * t * t * t * t + b;
    },
    "easeOutQuart": function easeOutQuart(t, b, c, d) {
        t /= d;
        t = t - 1;
        return -c * (t * t * t * t - 1) + b;
    },
    "easeInOutQuart": function easeInOutQuart(t, b, c, d) {
        t /= d / 2.0;
        if (t < 1) {
            return c / 2.0 * t * t * t * t + b;
        } else {
            t = t - 2;
            return -c / 2.0 * (t * t * t * t - 2) + b;
        }
    },

    //Quintic
    "easeInQuint": function easeInQuint(t, b, c, d) {
        t /= d;
        return c * t * t * t * t * t + b;
    },
    "easeOutQuint": function easeOutQuint(t, b, c, d) {
        t /= d;
        t = t - 1;
        return c * (t * t * t * t * t + 1) + b;
    },
    "easeInOutQuint": function easeInOutQuint(t, b, c, d) {
        t /= d / 2.0;
        if (t < 1) {
            return c / 2.0 * t * t * t * t * t + b;
        } else {
            t = t - 2;
            return c / 2.0 * (t * t * t * t * t + 2) + b;
        }
    }

};

},{}],9:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/*

MeganeTemplate

Version: 5.0.0
Website: http://megane-template.com/
License: Dentsu Isobar All Rights Reserved.

*/

var UA = function () {
    function UA() {
        _classCallCheck(this, UA);

        this._ua = navigator.userAgent;
        this._ver = navigator.appVersion;

        var ua = this._ua.toLowerCase();
        var ver = this._ver.toLowerCase();

        // IE(11以外)
        this.isMSIE = ua.indexOf('msie') > -1 && ua.indexOf('opera') == -1;
        // IE6
        this.isIE6 = this.isMSIE && ver.indexOf('msie 6.') > -1;
        // IE7
        this.isIE7 = this.isMSIE && ver.indexOf('msie 7.') > -1;
        // IE8
        this.isIE8 = this.isMSIE && ver.indexOf('msie 8.') > -1;
        // IE9
        this.isIE9 = this.isMSIE && ver.indexOf('msie 9.') > -1;
        // IE10
        this.isIE10 = this.isMSIE && ver.indexOf('msie 10.') > -1;
        // IE11
        this.isIE11 = ua.indexOf('trident/7') > -1;
        // IE
        this.isIE = this.isMSIE || this.isIE11;
        // Edge
        this.isEdge = ua.indexOf('edge') > -1;

        // Google Chrome
        this.isChrome = ua.indexOf('chrome') > -1 && ua.indexOf('edge') == -1;
        // Firefox
        this.isFirefox = ua.indexOf('firefox') > -1;
        // Safari
        this.isSafari = ua.indexOf('safari') > -1 && ua.indexOf('chrome') == -1;
        // Opera
        this.isOpera = ua.indexOf('opera') > -1;

        // iPhone
        this.isIphone = ua.indexOf('iphone') > 0;
        // iPod
        this.isIpod = ua.indexOf('ipod') > 0;
        // Android
        this.isAndroid = ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0;
        // Windows Phone
        this.isWinPhone = ua.indexOf('windows phone') > 0;
        // iPad
        this.isIpad = ua.indexOf('ipad') > 0;
        // Android Tablet (Android 4.1〜)
        this.isAndroidTab = ua.indexOf('android') > 0 && ua.indexOf('mobile') == -1;

        // Sp
        this.isSp = this.isIphone || this.isIpod || this.isAndroid || this.isWinPhone;
        // Tab
        this.isTab = this.isIpad || this.isAndroidTab;
        // Touch Device
        this.isTouch = 'createTouch' in document || 'ontouchstart' in document;
    }

    _createClass(UA, [{
        key: 'GetIosVersion',
        value: function GetIosVersion() {

            var v, versions;
            if (this.isIphone || this.isIpod || this.isIpad) {
                v = this._ver.match(/OS (\d+)_(\d+)_?(\d+)?/);
                versions = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
                return Number(versions[0] + "." + versions[1]);
            }
            return versions;
        }
    }, {
        key: 'GetAndroidVersion',
        value: function GetAndroidVersion() {

            if (this.isAndroid) {
                return Number(parseFloat(this._ua.slice(this._ua.indexOf("Android") + 8)));
            }
        }
    }]);

    return UA;
}();

exports.default = UA;

},{}],10:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/*

MeganeTemplate

Version: 5.0.0
Website: http://megane-template.com/
License: Dentsu Isobar All Rights Reserved.

*/

var AddClass = function AddClass(element, _className) {

    if (element.classList) {
        element.classList.add(_className);
    } else {
        element.className += ' ' + _className;
    }
};

var RemoveClass = function RemoveClass(element, _className) {

    if (element.classList) {
        element.classList.remove(_className);
    } else {
        element.className = element.className.replace(new RegExp('(^|\\b)' + _className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
};

var HasClass = function HasClass(element, _className) {

    var ignore = false;

    if (element.classList) {
        if (element.classList.contains(_className)) ignore = true;
    } else {
        if (new RegExp('(^| )' + _className + '( |$)', 'gi').test(e.target.className)) ignore = true;
    }

    return ignore;
};

var ToggleClass = function ToggleClass(element, _className) {

    if (element.classList) {
        element.classList.toggle(className);
    } else {
        var classes = element.className.split(' ');
        var existingIndex = classes.indexOf(className);

        if (existingIndex >= 0) classes.splice(existingIndex, 1);else classes.push(className);

        element.className = classes.join(' ');
    }
};

var SetCss = function SetCss(element, styles) {

    var css = "";

    for (var key in styles) {
        css += "" + key + ":" + styles[key] + "; ";
    }

    element.style.cssText = css;
};

var GetParents = function GetParents(element, target) {

    var parent = element,
        i = 0,
        t;

    if (target.split(".")[1]) {
        t = target.split(".")[1];
    } else if (target.split("#")[1]) {
        t = target.split("#")[1];
    } else {
        t = target;
    }
    while (i < 100) {
        parent = parent.parentNode;
        if (parent.tagName.toLowerCase() == t) break;
        if (parent.className) {
            if (parent.className.match(t)) break;
        } else if (parent.id) {
            if (parent.id.match(t)) break;
        }
        i++;
    }
    return parent;
};

var GetSiblings = function GetSiblings(el, target) {

    var siblings = Array.prototype.filter.call(el.parentNode.children, function (child, i) {

        var targetElm = el.parentNode.querySelectorAll(target);
        var target_ = null;

        for (var i = 0; i < targetElm.length; i++) {
            if (child === targetElm[i]) target_ = targetElm[i];
        }

        return child !== el && child === target_;
    });

    return siblings.length != 0 ? siblings : null;
};

var GetOffset = function GetOffset(element) {

    var BOX = element.getBoundingClientRect();

    return {
        top: BOX.top + window.pageYOffset - document.documentElement.clientTop,
        left: BOX.left + window.pageXOffset - document.documentElement.clientLeft
    };
};

var GetHeightData = function GetHeightData(element) {

    var STYLES = window.getComputedStyle(element);
    var H = element.offsetHeight;
    var BT = parseFloat(STYLES.borderTopWidth);
    var BB = parseFloat(STYLES.borderBottomWidth);
    var PT = parseFloat(STYLES.paddingTop);
    var PB = parseFloat(STYLES.paddingBottom);

    var DATA = {
        "height": H,
        "bordertop": BT,
        "borderBottom": BB,
        "paddingTop": PT,
        "paddingBottom": PB,
        "outerHeight": H + BT + BB + PT + PB
    };

    return DATA;
};

var GetWidthData = function GetWidthData(element) {

    var STYLES = window.getComputedStyle(element);
    var W = element.offsetWidth;
    var BT = parseFloat(STYLES.borderLeftWidth);
    var BB = parseFloat(STYLES.borderRightWidth);
    var PT = parseFloat(STYLES.paddingLeft);
    var PB = parseFloat(STYLES.paddingRight);

    var DATA = {
        "width": W,
        "borderLeft": BT,
        "borderRight": BB,
        "paddingLeft": PT,
        "paddingRight": PB,
        "outerWidth": W + BT + BB + PT + PB
    };

    return DATA;
};

var Not = function Not(element, ignoreTxt) {

    var ignore = false;

    var IGUNORE = ignoreTxt.split(".")[1] ? ignoreTxt.split(".")[1] : ignoreTxt.split("#")[1];

    if (element.classList) {
        if (element.classList.contains(IGUNORE)) ignore = true;
    } else {
        if (new RegExp('(^| )' + IGUNORE + '( |$)', 'gi').test(element.className)) ignore = true;
    }
    if (element.id == IGUNORE) ignore = true;

    return ignore;
};

// const AddedElmHandler = function( elm, callback, handler ) {
//
//     const EVENT = !handler ? "click" : handler;
//
//     document.addEventListener( EVENT, (e) => {
//         let hit = false,
//             t;
//         if ( elm.split(".")[1] ) {
//             t = elm.split(".")[1];
//         } else if ( elm.split("#")[1] ) {
//             t = elm.split("#")[1];
//         } else {
//             t = elm;
//         }
//         if( e.target.tagName.toLowerCase() == t ) {
//             hit = true;
//         } else {
//             if( e.target.className ) {
//                 if( e.target.className.match( t ) ) hit = true;
//             } else if( e.target.id ) {
//                 if( e.target.id.match( t ) ) hit = true;
//             }
//         }
//         if( hit ) {
//             e.preventDefault();
//             callback( e );
//         }
//     })
//
// }

// const CreatVideo = function( src, option ) {
//
//     var loop =  option.loop;
//     var volume =  option.volume;
//     var controls =  option.controls;
//     var nodownload =  option.nodownload;
//     var noContextmenu =  option.noContextmenu;
//
//     var video = document.createElement( 'video' );
//     video.setAttribute("playsinline", true);
//     if( controls ) video.setAttribute("controls", "controls");
//     if( nodownload ) video.setAttribute("controlslist", "nodownload");
//     if( noContextmenu ) video.setAttribute("onContextmenu", "return false");
//
//     video.loop = loop;
//     video.volume = volume;
//     video.src = src;
//     video.load();
//
//     return video;
//
// }

////

exports.AddClass = AddClass;
exports.RemoveClass = RemoveClass;
exports.HasClass = HasClass;
exports.SetCss = SetCss;
exports.GetParents = GetParents;
exports.GetSiblings = GetSiblings;
exports.Not = Not;
exports.GetOffset = GetOffset;
exports.GetHeightData = GetHeightData;
exports.GetWidthData = GetWidthData;

},{}],11:[function(require,module,exports){
'use strict';

var _common = require('./common/common');

var _common2 = _interopRequireDefault(_common);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

////

var Init = function Init() {

	(0, _common2.default)();
};

Init();

},{"./common/common":5}]},{},[11])

