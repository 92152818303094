
import { AddClass, RemoveClass, GetParents } from '../libs/j_utility';
import Ua from '../libs/j_ua';

import ShareSns from './_share';
import SearchPulldown from './_search_pulldown';
import ShowPagetop from './_show_pagetop';
import HoverTouch from './_hover_touch';

import jMenu from '../libs/j_menu_es';
import jSmoothScroll from '../libs/j_smoothScroll_es';

const Init =  () => {

    // ----------------------------------------------------------
    // UA
    // ----------------------------------------------------------

    const UA = new Ua();

    if( UA.isIE ) {
        AddClass( document.getElementsByTagName('html')[0], 'is-ie' );
    }
    if( UA.isFirefox ) {
        AddClass( document.getElementsByTagName('html')[0], 'is-ff' );
    }

    // ----------------------------------------------------------
    // HoverTouch
    // ----------------------------------------------------------

    HoverTouch();


    // ----------------------------------------------------------
    // ShowPagetop
    // ----------------------------------------------------------

    ShowPagetop();

    // ----------------------------------------------------------
    // Search Pulldown
    // ----------------------------------------------------------

    SearchPulldown()


    // ----------------------------------------------------------
    // Share
    // ----------------------------------------------------------

    ShareSns();


    // ----------------------------------------------------------
    // Menu
    // ----------------------------------------------------------

    const J_MENU = new jMenu(
        ".j-menu",
        {
            globalNav: ".j-menu_gnav",
            activeName: "is-open"
        }
    );

    const J_MENU_SEARCH = new jMenu(
        ".j-menu_search",
        {
            globalNav: ".j-menu_search_wrap",
            activeName: "is-open"
        }
    );

    const SEARCH_CLOSE_BTN = document.querySelector('.header-search .header-menubtn');
    SEARCH_CLOSE_BTN.addEventListener( 'click', () => {
        J_MENU_SEARCH.Close();
    } )


    // ----------------------------------------------------------
    // SmoothScroll
    // ----------------------------------------------------------

    const J_SCROLL = new jSmoothScroll(
        "a",
        {
            speed: 1000,
            easing: "easeOutQuint",
            ignore: ".ignore"
        }
    );
}

export default Init;
